<template>
  <div class="robot-edit">
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("robot-edit.header") }}
      </h3>

      <RobotForm
        :robot="data.robot"
        :onSubmit="onSubmitForm"
        v-if="data && data.robot"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/robot-edit/submit-form";
import InitRobotEditScreenInteractor from "@/business/robot-edit/init-robot-edit";
import RobotEditScreenController from "@/adapters/controllers/screen-robot-edit";
import Loader from "@/application/components/Loader";
import RobotForm from "@/application/components/robots/RobotForm";
// import AutoComplete from "@/application/components/AutoComplete";

export default {
  screen_name: "robot-edit",
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initRobotEditScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  components: { Loader, RobotForm },
  beforeMount() {
    this.controller = this.$injector.get(RobotEditScreenController);

    //{ INTERACTORS
    this.interactors.initRobotEditScreen = this.$injector.get(
      InitRobotEditScreenInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initRobotEditScreen.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmitForm() {
      const robot = this.data.robot;
      this.interactors.submitForm.handle(robot);
    }
  }
};
</script>

<style lang="scss"></style>
